@import '../../../../../../Foundation/Styling/code/thule/global/media-queries.less';
@import '@{variablesLocation}';

.general-item-container {
  &:not(.bg-color--white):not(.bg-color--black) {
    padding-top: @spacer9;
    padding-bottom: @spacer9;
  }

  .row {
    row-gap: @spacer9;
  }

  .general-item.col {
    flex-grow: 0;
    flex-basis: 100%;
  }

  @media screen and (min-width: @screen-lg) {
    &.number-of-columns-2 .general-item.col {
      flex-basis: 50%;
    }

    &.number-of-columns-3 .general-item.col {
      flex-basis: calc(100% / 3);
    }

    &.number-of-columns-4 .general-item.col {
      flex-basis: 25%;
    }
  }

  @media screen and (min-width: @screen-sm) and (max-width: @screen-md-max) {
    .content {
      &.narrow-style .general-item.col {
        flex-basis: 50%;
      }
    }
  }

  .general-item {
    .general-item-image {
      width: 100%;
    }

    .item-heading,
    .thule-btn-classic-link {
      color: @PitchBlack;
    }

    .text {
      font-size: 16px;
      color: @Gray80;

      * {
        font-size: 16px;
        color: @Gray80;
      }

      a {
        text-decoration: underline;
      }
    }
  }
}

.article-page {
  .general-item-container {
    @media (min-width: @screen-lg-min) {
      margin-left: 8.33333333%;
      flex: 0 0 auto;
      width: 83.33333333%;
    }

    @media (min-width: @screen-xl-min) {
      margin-left: 16.66666667%;
      flex: 0 0 auto;
      width: 66.66666667%;
    }

    .row {
      column-gap: 1rem;

      @media (min-width: @screen-lg-min) {
        column-gap: 2rem;
      }

      .col {
        padding: 0 2rem;
      }

      .col:last-child {
        @media (min-width: @screen-md-min) {
          padding-left: 0;
          padding-right: 2rem;
        }

        @media (min-width: @screen-lg-min) {
          padding-left: 0;
          padding-right: 1rem;
        }

        @media (min-width: @screen-xl-min) {
          padding: 0;
        }
      }

      .col:first-child {
        @media (min-width: @screen-md-min) {
          padding-left: 2rem;
          padding-right: 0;
        }

        @media (min-width: @screen-lg-min) {
          padding-left: 1rem;
          padding-right: 0;
        }

        @media (min-width: @screen-xl-min) {
          padding: 0;
        }
      }
    }

    @media screen and (min-width: @screen-md-min) {
      &.number-of-columns-2 .general-item.col {
        flex-basis: calc(50% - 5px);
      }

      &.number-of-columns-3 .general-item.col {
        flex-basis: calc(33% - 5px);
      }

      &.number-of-columns-4 .general-item.col {
        flex-basis: calc(25% - 5px);
      }
    }

    @media screen and (min-width: @screen-lg-min) {
      &.number-of-columns-2 .general-item.col {
        flex-basis: calc(50% - 10px);
      }

      &.number-of-columns-3 .general-item.col {
        flex-basis: calc(33% - 10px);
      }

      &.number-of-columns-4 .general-item.col {
        flex-basis: calc(25% - 10px);
      }
    }

    .general-item {
      .image-container {
        background-color: @Gray10;
      }
    }
  }
}
.general-item-container.bg-color--black {
  color: @White;

  .item-heading,
  .thule-btn-classic-link {
    color: @White;
  }

  .text {
    color: @White;

    * {
      color: @White;
    }
  }
}
